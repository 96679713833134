export const projects = [
	{
		title: "MoodLifter Web application",
		img: "./assets/images/moodlifter.png",
		tags: [
			"HTML",
			"CSS",
			"REACT",
			"TYPESCRIPT",
			"NODE",
			"EXPRESS",
			"POSTGRESSQL",
			"PRISMA ORM",
		],
		text: "MoodLifter is an app designed to help users improve their mood by providing them with motivational quotes, articles, and videos. Users can create an account, save their favorite quotes, videos, and motivational quotes, and access them on their profile page. The app also allows users to keep daily journals, which can be saved into a database for future reference, enabling users to reflect on both good and bad days.",
		github_link: "https://github.com/hannah68/MoodLifter",
		demo_link: "",
	},
	{
		title: "WoodLand Website",
		img: "./assets/images/woodland.png",
		tags: [
			"HTML",
			"CSS",
			"REACT",
			"FIGMA",
			"NODE",
			"EXPRESS",
			"MONGODB",
			"CLOUDINARY",
			"MONGOOSE",
		],
		text: "This is an e-commerce website for a furniture shop where users can browse furniture products and filter them based on various criteria such as color, type, price, and collection. Additionally, users can create an account and add products to their basket, write reviews, and edit the items in the basket. The website also allows users to sort search results based on the lowest and highest prices available.",
		github_link: "https://github.com/hannah68/Woodland-e-commerce-react-",
		demo_link: "",
	},
	{
		title: "Pokimo & King Website",
		img: "./assets/images/pokemon.png",
		tags: [
			"HTML",
			"CSS",
			"REACT",
			"TYPESCRIPT",
			"NODE",
			"EXPRESS",
			"POSTGRESSQL",
			"PRISMA",
		],
		text: "As a first full-stack group project, we created a pokemon forum with a custom backend API. We used React in the front end and PostgreSQL along with Express in the back end. I mainly worked on the front end tasks",
		github_link: "https://github.com/hannah68/project-team-fullstack",
		demo_link: "",
	},
	{
		title: "Shoprime Website",
		img: "./assets/images/shopprime.png",
		tags: [
			"HTML",
			"CSS",
			"TYPESCRIPT",
			"ANGULAR",
			"MATERIALUI",
			"TAILWINDCSS",
			"STRIPE",
		],
		text: "Shoprime is an e-commerce single-page application (SPA) created using Angular.Users can filter products based on categories, sort them by price, add items to their basket, edit the basket, and proceed to checkout. The app is built using Angular and Typescript for the frontend, and uses Tailwindcss and MaterialUi for styling.",
		github_link: "https://github.com/hannah68/shoprime",
		demo_link: "",
	},
	{
		title: "TravelGo Website",
		img: "./assets/images/travelGo.png",
		tags: [
			"HTML",
			"CSS",
			"REACT",
			"JAVASCRIPT",
			"FIGMA",
			"STYLED COMPONENT",
			"JSON SERVER",
		],
		text: "TravelGo is a travel agency website built using React and styled components.The website includes a user interface designed using Figma wireframes. With react styled components, TravelGo offers a visually appealing and responsive website for users to easily browse and book their travel needs.",
		github_link: "https://github.com/hannah68/TravelGo",
		demo_link: "",
	},
	{
		title: "My Portfolio Website",
		img: "./assets/images/portfolio.png",
		tags: ["HTML", "CSS", "JAVASCRIPT", "SASS"],
		text: "This is the first version of my website that I created without using any frameworks. There are multiple websites and apps inside this portfolio that I built with CSS, Bootstrap, SASS, Javascript, Chart.js, Bulma, Materialize and External API.",
		github_link: "https://github.com/hannah68/My-Portfolio",
		demo_link: "https://reihaneh-portfolio.netlify.app/",
	},
];
